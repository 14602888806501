/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * This module provides access to firebase configuration and services for the frontend UI.
 *
 * Note that there are sections of configuration below which are filled in by scripts.
 * These are run manually during local testing, or by the deploy script during deployment.
 *
 * The exports are imported into front end code to provide access to Firebase Functions deployed in the cloud.
 * Any logging performed here will appear in the user's browser's JS console.
 * Note also that therefore we have no control over environment variables.
 */

// Import the individual services that we will use in Firebase:
import { initializeFirestore, collection, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getApp, initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

console.log("firebase-init");

// Name of the Firebase app with access to ebx-core services
const FIREBASE_CORE_NAME = 'core';

// ----------------------------------------------------------------------------
// Firebase app configuration
// ----------------------------------------------------------------------------
// XXX This config section will be filled in by insertFirebaseConfig.sh
const firebaseConfig = {
  "projectId": "ebx-trial-dev",
  "appId": "1:1056791888046:web:7923ad5946fa5236a66d3f",
  "databaseURL": "https://ebx-trial-dev-default-rtdb.europe-west1.firebasedatabase.app",
  "storageBucket": "ebx-trial-dev.appspot.com",
  "locationId": "europe-west2",
  "apiKey": "AIzaSyDk0MRqwiLVvxp2yAAeBx8yJ5VrP-N2_sU",
  "authDomain": "ebx-trial-dev.firebaseapp.com",
  "messagingSenderId": "1056791888046",
  "measurementId": "G-1NB3LZ031D"

};

// XXX This config section will be filled in by insertFirebaseConfig.sh
const firebaseCoreConfig = {
  "projectId": "ebx-core",
  "appId": "1:629678258428:web:9e56bf8665898a06983f79",
  "storageBucket": "ebx-core.appspot.com",
  "locationId": "europe-west2",
  "apiKey": "AIzaSyB6PuTvJ6koLP088rpSv4NbhrC46wvzmIs",
  "authDomain": "ebx-core.firebaseapp.com",
  "messagingSenderId": "629678258428",
  "measurementId": "G-HL5PCTYWDZ"

};

// XXX The value of the maps API key will be filled in by setMapsApiKey.sh
const mapsApiKey = '';

// Change authDomain if necessary to match our custom domain
const CUSTOM_DOMAIN_MAPPINGS = {
    "ebx-trial-dev": "dev.earthblox.io",
    "ebx-app": "app.earthblox.io",
}

const LOAD_BALANCER_DOMAINS = { // maps the projectId to a load balancer domain. Used by the functions so that the FE calls the LB instead of the function directly
    "ebx-trial-dev": "dev.lb.earthblox.io",
    "ebx-staging-server": "staging.lb.earthblox.io",
    "ebx-standby": "standby.lb.earthblox.io",
    "ebx-app": "lb.earthblox.io"
}

if (Object.hasOwn(CUSTOM_DOMAIN_MAPPINGS, firebaseConfig.projectId)) {
    firebaseConfig.authDomain = CUSTOM_DOMAIN_MAPPINGS[firebaseConfig.projectId]
}

// Initialize Firebase apps
initializeApp(firebaseConfig);
const coreApp = initializeApp(firebaseCoreConfig, FIREBASE_CORE_NAME);

const projectId = firebaseConfig.projectId;


// ----------------------------------------------------------------------------
// Environment setup
// ----------------------------------------------------------------------------
// - settings for LTI projects
// - use emulators in dev mode
// ----------------------------------------------------------------------------
// Is this an LTI-integrated project? Note we don't include UOE in this, which has a MOOC organisation but is primarily a normal install.
let requiresLTIIntegration = projectId==="ebx-mooc" || projectId==="eb-edina"; // || projectId==="ee-uoe";
console.debug(`firebase.js setup: projectId ${projectId} requiresLTIIntegration ${requiresLTIIntegration}`);
// If this is an LTI-integrated project, set auth persistence to none; otherwise Firebase tries
// to persist the auth info locally on the broswser, which requires third party cookies
// when running in an IFrame within FutureLearn. Session does not work with Node.js
// See https://firebase.google.com/docs/auth/web/auth-state-persistence
// if (requiresLTIIntegration) {
//     import { getAuth, connectAuthEmulator, Persistence } from "firebase/auth";
//     setPersistence(auth, Persistence.NONE);
// }

// ----------------------------------------------------------------------------
// Firestore Settings
// ----------------------------------------------------------------------------
const dbSettings = {
    merge: true
}
const coreDbSettings = {
    merge: true
}
// The setting experimentalAutoDetectLongPolling should detect polling issues
// and turn on long polling when necessary. However reports suggest it is not
// entirely reliable and we may need to decide ourselves when to enable
// experimentalForceLongPolling instead.
// NB This breaks emulation so only do it if we're not in a dev/localhost environment
// We can enable it on coreDB as we don't emulate that
console.info("Enabling experimentalAutoDetectLongPolling on ebx-core Firestore service")
coreDbSettings.experimentalAutoDetectLongPolling = true;
if (import.meta.env.DEV || process.env.NODE_ENV==="development" || location.hostname === "localhost") {
    // XXX Note we enable force long polling in dev/emulated environment, in order to
    // make e2e work with Firebase; but disable autodetect as it breaks emulators.
    // See https://github.com/earthblox-io/earthbloxnextgen/commit/6f8e83744a36fd7448b987c598018098ba0c97b5
    console.info("Disabling experimentalAutoDetectLongPolling in emulation environment")
    // Turn off detection, which is enabled by default from Firebase 9.22.0
    // See https://firebase.google.com/support/release-notes/js#version_9220_-_may_12_2023
    dbSettings.experimentalAutoDetectLongPolling = false
    dbSettings.experimentalForceLongPolling = true
}

// ----------------------------------------------------------------------------
// Service references
// ----------------------------------------------------------------------------

// Project-specific services
const app = getApp();
const db = initializeFirestore(app, dbSettings);
const auth = getAuth(app);

// EBX Core services
const coreDB = initializeFirestore(coreApp, coreDbSettings);
// Reference to the default storage bucket
const coreDefaultStorageBucket = getStorage(coreApp);

var LOAD_BALANCER_DOMAIN = null
if (LOAD_BALANCER_DOMAINS[projectId]){
    LOAD_BALANCER_DOMAIN = `https://${LOAD_BALANCER_DOMAINS[projectId]}`;
}

// ----------------------------------------------------------------------------
// Storage references
// ----------------------------------------------------------------------------
// Reference to the default storage bucket
const defaultStorageBucket = getStorage(app);
// Unscanned Bucket
const UNSCANNED_BASE_URL = `gs://unscanned-earthengine-upload-${projectId}`
const unscannedBucketRef = getStorage(app, UNSCANNED_BASE_URL);

// ----------------------------------------------------------------------------
// Emulation
// ----------------------------------------------------------------------------
// Use the db and auth emulators if in development mode
if (process.env.NODE_ENV==="development" || location.hostname === "localhost") {
    console.info("Localhost environment: Using firestore and auth emulators");
    connectFirestoreEmulator(db, "localhost", 8080);
    connectAuthEmulator(auth, "http://localhost:9099/");
    connectStorageEmulator(defaultStorageBucket, "localhost", 9199);
    connectStorageEmulator(unscannedBucketRef, "localhost", 9199);
    // NOTE any non-default buckets which we want to emulate will require
    // another emulator and port
}

// Firestore collections
const earthbloxCollection = collection(db, 'earthblox')
const googleMarketPlaceCollection = collection(db, 'googleMarketPlace')
const organisationsCollection = collection(db, 'organisations')
const usersCollection = collection(db, 'users')
const sharedCollection = collection(db, 'shared')
const apiVersionCollection = collection(db, 'api_version');



export {
    db,
    auth,
    app,
    earthbloxCollection,
    googleMarketPlaceCollection,
    organisationsCollection,
    sharedCollection,
    usersCollection,
    apiVersionCollection,
    defaultStorageBucket,
    firebaseConfig,
    mapsApiKey,
    // Export ebx-core services
    coreDB,
    coreDefaultStorageBucket,
    projectId,
    LOAD_BALANCER_DOMAIN,
    unscannedBucketRef
}
