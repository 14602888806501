<template>
    <md-dialog v-model:md-active="isDialogVisible" :md-click-outside-to-close="false">
        <md-dialog-title>Bulk Add Users</md-dialog-title>
        <md-dialog-content>
            <md-field v-if="isSuperAdmin">
                <label>Organisation</label>
                <md-select v-model="orgId" name="orgId">
                <md-option
                    v-for="organisation in organisations"
                    :key="organisation.id"
                    :value="organisation.id"
                    >{{ organisation.name }}</md-option
                >
                </md-select>
            </md-field>

            <md-field>
                <label>Select file with the list of users</label>
                <md-file v-model="file" accept=".xlsx, .csv" @change="fileSelected" />
            </md-field>

            <md-table v-if="usersToAdd">
                <md-table-row>
                    <md-table-head>Email</md-table-head>
                    <md-table-head>Password</md-table-head>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Roles</md-table-head>
                    <md-table-head>Status</md-table-head>
                </md-table-row>

                <md-table-row v-for="user in usersToAdd" :key="user.email" :class="getUserRowClass(user)">
                    <md-table-cell>{{ user.email }}</md-table-cell>
                    <md-table-cell>{{ user.password }}</md-table-cell>
                    <md-table-cell>{{ user.displayName }}</md-table-cell>
                    <md-table-cell>{{ user.roles.join(', ') }}</md-table-cell>
                    <md-table-cell>{{ user.statusMessage }}</md-table-cell>
                </md-table-row>
            </md-table>
        </md-dialog-content>

        <md-dialog-actions>
            <md-button
                class="md-accent"
                @click="addUsers"
                v-if="areUsersValid"
                :disabled="started || finished"
            >Add Users</md-button>
            <md-button class="md-primary" @click="closeDialog">Close</md-button>
        </md-dialog-actions>

        <md-snackbar
            :md-position="'center'"
            :md-duration="10000"
            v-model:md-active="showSnackbar"
            md-persistent
        >
            <span>{{ message }}</span>
        </md-snackbar>
    </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { organisationsCollection } from "@/firebase.js";
import { doc as firestoreDoc, getDoc, getDocs, query } from "firebase/firestore";
import { SpreadsheetService } from "@/services/spreadsheet.service";
import { CsvService } from "@/services/csv.service";
import { functions } from "../firebaseFunctions.js";
import authMixin from "@/components/mixins/authMixin";
import { USER_EXPLORER_ROLE, USER_CREATOR_ROLE} from "@/constants/appConstants";

const validRoles = [USER_EXPLORER_ROLE, USER_CREATOR_ROLE];

const StatusEnum = Object.freeze({
    INVALID: "Invalid",
    PENDING: "Pending",
    IN_PROGRESS: "In Progress",
    SUCCESS: "Success",
    ERROR: "Error",
});

export default {
    name: "UserListBulkAddUsers",
    mixins: [authMixin],
    data: () => ({
        organisations: [],
        authenticatedUser: null,
        orgId: null,
        file: null,
        usersToAdd: null,
        showSnackbar: false,
        message: "",
        isDialogVisible: false,
    }),
    emits: [
        "dialog-closed"
    ],
    methods: {
        showDialog() {
            this.file = null;
            this.usersToAdd = null;
            this.message = "";
            this.showSnackbar = false;
            this.isDialogVisible = true;
            this.orgId = this.user.orgId;
        },
        closeDialog() {
            this.$emit("dialog-closed");
            this.isDialogVisible = false;
        },
        prepareUser(user) {
            // adds required fields to user object
            user.orgId = this.orgId;
            user.sendEmail = true;
            user.bulkAdded = true;
            // this is run by superadmin or trusted admins, so we assume the email addresses can be trusted and so manual verification is not required => set the following to true
            user.emailVerified = true;

            if (!user.password) {
                user.password = this.generateOWASPComplientPassword()
            }

            return user;
        },
        validateUser(user) {
            if (!user.email || !user.displayName || !user.password || !user.roles || !user.roles.length) {
                user.status = StatusEnum.INVALID;
                user.statusMessage = "Email, Name, Password and Roles are required.";
                return user;
            }

            const invalidRoles = user.roles.filter(role => !validRoles.includes(role));
            if (invalidRoles.length > 0) {
                user.status = StatusEnum.INVALID;
                user.statusMessage = `Invalid roles: ${invalidRoles.join(", ")}.`;
                return user;
            }

            user.status = StatusEnum.PENDING;
            user.statusMessage = StatusEnum.PENDING;
            return user;
        },
        async getOrgQuotas() {
            const currentOrgId = this.orgId;

            if (currentOrgId === null || currentOrgId === undefined) {
                return null;
            }

            const doc = await getDoc(firestoreDoc(organisationsCollection, currentOrgId));

            if (!doc.exists()) {
                return null;
            }

            const response = {};

            const docData = doc.data();

            response.maxExplorerUsers = docData?.maxExplorerUsers || 0;

            response.maxExplorerUsersCount = docData?.maxExplorerUsersCount || 0;

            return response;
        },
        /**
         * Validates users, returns users list
         * @param {*} users 
         */
        async validateOrgQuotas(users) {
            if (this.isSuperAdmin) {
                console.warn("Not applying org quota validation as user is super admin.")
                return users;
            }

            let quotas = null;

            try {
                quotas = await this.getOrgQuotas();
            } catch (error) {
                console.error("Error while fetching org quotas", error);
                return users;
            }

            if (quotas === null) {
                return users;
            }

            const maxExplorerUsers = quotas.maxExplorerUsers;
            const maxExplorerUsersCount = quotas.maxExplorerUsersCount;

            const explorerUsers = users.filter(user => user.roles.includes(USER_EXPLORER_ROLE));

            if (maxExplorerUsersCount + explorerUsers.length <= maxExplorerUsers) {
                return users;
            }

            // add status to users that would exceed the maximum number of explorer users for this organisation
            users = users.map(user => user.roles.includes(USER_EXPLORER_ROLE) ? {...user, status: StatusEnum.INVALID, statusMessage: `Adding this user would exceed the maximum number of explorer users for this organisation.`} : user)

            this.message = `Adding ${explorerUsers.length} user${explorerUsers.length > 1 ? "s" : ""} would exceed the maximum number of explorer users for this organisation.`;
            this.showSnackbar = true;

            return users;
        },
        fileSelected(event) {
            let file = event.target.files[0];

            const extension = file.name.split('.').pop();

            let usersPromise;
            if (extension === 'csv') {
                usersPromise = CsvService.readUsersFromCsv(file);
            } else if (extension === 'xlsx') {
                usersPromise = SpreadsheetService.readUsersFromSpreadsheet(file);
            } else {
                this.message = `File type '${extension}' is not supported.`;
                this.showSnackbar = true;
                return;
            }

            usersPromise.then(
                async (usersToAdd) => {

                    usersToAdd = usersToAdd.map(user => this.validateUser(this.prepareUser(user)))
                    usersToAdd = await this.validateOrgQuotas(usersToAdd);

                    this.usersToAdd = usersToAdd;
                },
                (error) => {
                    console.error(error);
                    this.message = `Error while loading users from file '${file.name}'.`;
                    this.showSnackbar = true;
                }
            );
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
        },
        generateOWASPComplientPassword(){
            const characterSet = "abcdefghijklmnopqrstuvwxyz"
            let characters = [];
            
            while (characters.length < 6) {
                let newChar = characterSet.charAt(this.getRandomInt(0, characterSet.length));

                if (characters.length === 0) {
                    characters.push(newChar);
                    continue;
                }

                // cannot have 3 repeating characters
                if (newChar === characters[characters.length -1] && newChar === characters[characters.length -2]) {
                    continue;
                }

                characters.push(newChar);
            }

            let uppercaseSection = characters.splice(0, 3).join('').toUpperCase();
            let lowercaseSection = characters.join('');

            return `${uppercaseSection}_${this.getRandomInt(11,99)}_${lowercaseSection}`
        },
        addUsers() {
            if (this.started || !this.usersToAdd || !this.usersToAdd.length) {
                return;
            }

            for (let i = 0; i < this.usersToAdd.length; i++) {
                let user = this.usersToAdd[i];
                user.status = StatusEnum.IN_PROGRESS;
                user.statusMessage = StatusEnum.IN_PROGRESS;

                functions.addUser(user).then(
                    () => {
                        user.status = StatusEnum.SUCCESS;
                        user.statusMessage = StatusEnum.SUCCESS;
                        this.$forceUpdate();
                    },
                    (error) => {
                        console.error(error, user);
                        user.status = StatusEnum.ERROR;
                        user.statusMessage = `Error: ${error.message}`;
                        this.$forceUpdate();
                    }
                );
            }
        },
        getUserRowClass: (user) => ({
            'md-selected-single md-primary warning': user.status === StatusEnum.INVALID || user.status === StatusEnum.ERROR, 
            'md-selected-single md-accent': user.status === StatusEnum.SUCCESS,
            'md-selected-single': user.status === StatusEnum.IN_PROGRESS,
        }),
        setOrganisations(isSuperAdmin) {
            if (!isSuperAdmin) {
                this.organisations = [];
                return;
            }

            getDocs(query(organisationsCollection)).then((querySnapshot) => {
                let organisations = [];
                querySnapshot.forEach((doc) => {
                    let docData = doc.data();
                    organisations.push({
                        id: doc.id,
                        name: docData.name,
                    });
                });
                this.organisations = organisations;
            });
        },
    },
    created() {
        this.setOrganisations(this.isSuperAdmin);
    },
    watch: {
        isSuperAdmin: function(newValue) {
            this.setOrganisations(newValue);
        }
    },
    computed: {
        areUsersValid() {
            const isUserList = this.usersToAdd && this.usersToAdd.length > 0;

            if (!isUserList) {
                return false;
            }

            const areUsersValid = this.usersToAdd.every(user => user.status !== StatusEnum.INVALID);
            return isUserList && areUsersValid;
        }, 
        started() {
            return this.usersToAdd.some(user => user.status === StatusEnum.IN_PROGRESS);
        },
        finished() {
            return this.usersToAdd.every(user => user.status === StatusEnum.SUCCESS || user.status === StatusEnum.ERROR);
        },
    }
};
</script>

<style scoped lang="scss">
    .md-table.md-theme-default .md-selected-single.md-primary.warning {
        background-color: #ef4748;
    }

    @media (min-width: 601px) {
        .md-dialog-content {
            width: 80vw;
        }
    }
</style>