import {usersCollection} from '@/firebase.js'
import { doc as firestoreDoc, updateDoc, collection, onSnapshot } from "firebase/firestore";

/**
 * What does this store need to do?
 * 1. get an asset operation doc from database, based on id?
 * 2. watch the asset operation doc for changes
 * 3. do we need to be able to update the asset operation doc?
 *     - for example, to record the user has seen the prompt and therefore not show it again
 */

const state = {
    userOperations: [],
    isListeningToUserOperations: false,
    operationSnapshotUnsubscribe: null,
    successStates: ['SUCCEEDED', 'COMPLETED'],
    failedStates: ['FAILED','ERROR','ERRORED','CANCELLED', 'REJECTED'],
}

const getters = {}

const mutations = {
    setUserOperations(state, userOperations) {
        state.userOperations = userOperations;
    },
    setIsListeningToUserOperations(state, isListening) {
        state.isListeningToUserOperations = isListening;
    },
    setUnsubscribeListener(state, unsubscribe) {
        state.operationSnapshotUnsubscribe = unsubscribe;
    }
}

const actions = {
    // gather up the user operations for the user
    async queryUserOperations(context, userId) {
        if (context.state.isListeningToUserOperations || !userId) {
            return;
        }
        const unsubscribe = onSnapshot(collection(usersCollection, userId, 'operations'), (snapshot) => {
            let userOperations = [];
            snapshot.forEach((doc) => {
                const docId = doc.id;
                const operation = doc.data();
                // add operation doc to FE if it has not been dismissed, and is Geotiff upload
                if (operation.hasDismissedAssetNotification) {
                    if (!operation.hasDismissedAssetNotification.isFinished || !operation.hasDismissedAssetNotification.isUploading) {
                        // TODO do we want to restrict what we pull back from the database?
                        userOperations.push({...operation, docId: docId});
                    }
                }
            });
            context.commit('setUserOperations', userOperations);
        }, error => {
            console.error('user assets', error);
        });
        context.commit('setUnsubscribeListener', unsubscribe);
        context.commit('setIsListeningToUserOperations', true);
    },
    async unsubscribeUserOperations(context) {
        if (context.state.operationSnapshotUnsubscribe) {
            context.state.operationSnapshotUnsubscribe();
            context.commit('setIsListeningToUserOperations', null);
        }
    },
    async dismissAssetNotification(context, updateObj) {
        const userId = updateObj.userId;
        const operation = updateObj.operation;

        // update this in firestore operation doc as well
        const hasDismissedAssetNotification = {
            isFinished: false,
            isUploading: false
        }
        // if user dismissed after the operation is finished (either success or failure), then don't show the notification again
        if (context.state.successStates.includes(operation.state) || context.state.failedStates.includes(operation.state)) {
            hasDismissedAssetNotification.isFinished = true;
            hasDismissedAssetNotification.isUploading = true;
        } else {
            //  otherwise, if the user dismissed the notification while the operation is still running, then show the notification again
            hasDismissedAssetNotification.isFinished = false;
            hasDismissedAssetNotification.isUploading = true;
        }
        const operationDocRef = firestoreDoc(usersCollection, userId, 'operations', operation.docId);
        updateDoc(operationDocRef, {hasDismissedAssetNotification: hasDismissedAssetNotification});
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
