<template>
    <div>
        <div ref="showDialog" class="modal-ms">
            <GoogleMapPolygonUploadFileSelection
                v-model="showDialog"
                :add-to-map="false"
                :clear-files="clearFiles"
                :upload-error="uploadError"
                :upload-error-message="uploadErrorMessage"
                :upload-in-progress="isLoading"
                :forceUpload="true"
                @cancel-upload="cancelUpload"
                @clear-upload-error="clearUploadError"
                @dialog-closed="dialogClosed"
                @start-upload="startUpload"
                />
        </div>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

// disable eslint for the entire file
/* eslint-disable */

import authMixin from "@/components/mixins/authMixin";
import { unscannedBucketRef } from "@/firebase.js";
import GoogleMapPolygonUploadFileSelection from '@/components/ResultMap/GoogleMapPolygonUploadFileSelection.vue';
import { serverTimestamp, doc as firestoreDoc, setDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { INGEST_TABLE_OP_TYPE } from '@/constants/appConstants.js';

export default {
    name: 'AreasUploadWrapper',
    mixins: [authMixin],
    components: {
        GoogleMapPolygonUploadFileSelection
    },
    data() {
        return {
            showDialog: false,
            files: [],
            clearFiles: false,
            uploadError: false,
            uploadErrorMessage: '',
            isLoading: false,
            alertActive: false,
            alertMessage: '',
        }
    },
    methods: {
        clearUploadError() {
            this.uploadError = false;
            this.uploadErrorMessage = "";
        },
        removeFile() {
            this.filesToUpload = []
            this.clearFiles = true
        },
        cancelUpload() {
            this.showDialog = false;
            this.cleanUpAfterUpload();
        },
        cleanUpAfterUpload() {
            this.dialogClosed();
            this.isLoading = false;
            this.removeFile();
        },
        dialogClosed(){
            this.loadingStatus = 'Loading';
        },
        startUpload(files, isUpload, splitProperty, formData, isCsv) {
            if (isCsv) {
                this.files = [files[1]];
            } else {
                this.files = [files[0]];
            }
            this.formData = formData;
            this.uploadFiles()
        },
        handleShowAlert(alertMessage) {
            this.alertMessage = alertMessage.alertMessage;
            this.alertActive = true;
        },
        openDialog() {
            this.showDialog = true;
        },
        async uploadFiles() {
            this.isLoading = true;

            const uploadJob = uuidv4();
            const storagePath = `${this.user.orgId}/${this.user.uid}/${uploadJob}`
            const fileList = this.files;
            const formData = this.formData;

            let currentState = "UPLOADING";

            const operationDocRef = await this.createOperationDoc(fileList, uploadJob, storagePath, formData);

            if(operationDocRef === false) {
                this.isLoading = false;
                this.removeFile();
                return;
            }

            try {
                await this.uploadToStorageBucket(fileList, storagePath);
            } catch (err) {
                console.error("File failed to upload", err)
                this.removeFile();
                currentState = "ERROR";
                updateDoc(operationDocRef, {
                    state: currentState,
                    updatedAt: serverTimestamp()
                })

                this.isLoading = false;
                this.loadingStatus = currentState
                this.alertMessage = "There was an error uploading your file. Please try again."
                this.alertActive = true;
                return;
            }

            currentState = "SCANNING";

            updateDoc(operationDocRef, {
                state: currentState,
                updatedAt: serverTimestamp()
            })
            
            this.loadingStatus = currentState;

            this.isLoading = false;
            this.showDialog = false;
            this.cleanUpAfterUpload();


            this.removeFile();
        },
        async createOperationDoc(filesToUpload, uploadJob, storagePath, formData) {
            let docId = `${this.user.uid}_${uploadJob}`;
            const docRef = firestoreDoc(this.userRef, "operations", docId);
            
            // TODO replace with psuedo state enum
            let currentState = "UPLOADING"

            let docFields = {
                files: filesToUpload.map((file) => file.name), // array of file names (if we decide to allow multiple files)
                filename: filesToUpload[0].name, // for single file
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                state: currentState,
                submittedBy: this.user.uid,
                task_type: INGEST_TABLE_OP_TYPE,
                hasDismissedAssetNotification: {
                    isUploading: false,
                    isFinished: false,
                }
            }

            if (formData.csvColumnsInfo && formData.csvColumnsInfo.length > 0) {
                docFields.formData = {
                    csvColumnsInfo: formData.csvColumnsInfo
                }
            }

            if (formData.latColumn) { 
                docFields.formData = { 
                    ...docFields.formData,
                    latColumn: formData.latColumn
                }
            }

            if (formData.lonColumn) {
                docFields.formData = {
                    ...docFields.formData,
                    lonColumn: formData.lonColumn
                }
            }

            if (formData.selectedProjection) {
                docFields.formData = {
                    ...docFields.formData,
                    selectedProjection: formData.selectedProjection
                }
            }

            await setDoc(docRef, docFields)

            this.loadingStatus = currentState

            return docRef
        },
        async uploadToStorageBucket(fileList, storagePath) {
            // function that uploads file list to storage bucket
            const totalFiles = fileList.length;
            let nUploadedFiles = 0

            return new Promise((resolve, reject) => {
                // https://firebase.google.com/docs/storage/web/upload-files#full_example
                fileList.forEach((file) => {
                    let fileName = file.name
                    if (fileName.indexOf(' ') >= 0) {
                        fileName = fileName.replaceAll(' ', '_')    
                    }
                    const bucket = storageRef(unscannedBucketRef, `${storagePath}/${fileName}`);
                    
                    uploadBytesResumable(bucket, file).on('state_changed',
                        (snapshot) =>{
                            // task progress
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.info("file is " + progress + "% done")
                        },
                        (error) => {
                            // full error list here
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            console.error("Error in uploading file, more details below:", file)
                            console.error("Error code", error.code)
                            console.error(error)
                            reject(file)
                        },
                        () => {
                            // upload complete
                            nUploadedFiles++;
                            // if all files have finished uploading inform uploadFiles
                            if(totalFiles === nUploadedFiles){
                                console.log("finished uploading")
                                resolve();
                            }
                        }
                    );
                });
            })

            
        },
    }
}

</script>